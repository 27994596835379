const actions = {
  XLOG_READ_BEGIN: 'XLOG_READ_BEGIN',
  XLOG_READ_SUCCESS: 'XLOG_READ_SUCCESS',
  XLOG_READ_ERR: 'XLOG_READ_ERR',
  TXN_READ_BEGIN: 'TXN_READ_BEGIN',
  TXN_READ_SUCCESS: 'TXN_READ_SUCCESS',
  TXN_READ_ERR: 'TXN_READ_ERR',
  TXN_DETAIL_READ_BEGIN: 'TXN_DETAIL_READ_BEGIN',
  TXN_DETAIL_READ_SUCCESS: 'TXN_DETAIL_READ_SUCCESS',
  TXN_DETAIL_READ_ERR: 'TXN_DETAIL_READ_ERR',
  OBJECT_READ_BEGIN: 'OBJECT_READ_BEGIN',
  OBJECT_READ_SUCCESS: 'OBJECT_READ_SUCCESS',
  OBJECT_READ_ERR: 'OBJECT_READ_ERR',

  xlogReadBegin: () => {
    return {
      type: actions.XLOG_READ_BEGIN,
    };
  },

  xlogReadSuccess: (data) => {
    return {
      type: actions.XLOG_READ_SUCCESS,
      data,
    };
  },

  xlogReadErr: (err) => {
    return {
      type: actions.XLOG_READ_ERR,
      err,
    };
  },

  txnReadBegin: () => {
    return {
      type: actions.TXN_READ_BEGIN,
    };
  },

  txnReadSuccess: (data) => {
    return {
      type: actions.TXN_READ_SUCCESS,
      data,
    };
  },

  txnReadErr: (err) => {
    return {
      type: actions.TXN_READ_ERR,
      err,
    };
  },

  txnDetailReadBegin: () => {
    return {
      type: actions.TXN_DETAIL_READ_BEGIN,
    };
  },

  txnDetailReadSuccess: (data) => {
    return {
      type: actions.TXN_DETAIL_READ_SUCCESS,
      data,
    };
  },

  txnDetailReadErr: (err) => {
    return {
      type: actions.TXN_DETAIL_READ_ERR,
      err,
    };
  },

  objectReadBegin: () => {
    return {
      type: actions.OBJECT_READ_BEGIN,
    };
  },

  objectReadSuccess: (data) => {
    return {
      type: actions.OBJECT_READ_SUCCESS,
      data,
    };
  },

  objectReadErr: (err) => {
    return {
      type: actions.OBJECT_READ_ERR,
      err,
    };
  },
};

export default actions;
