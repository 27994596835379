import actions from './actions';

const initialState = {
  data: {
    loop: '0',
    index: '0',
    xlogs: [],
  },
  txn: {
    loading: false,
    error: null,
    data: [],
  },
  txnDetail: {
    loading: false,
    error: null,
    data: [],
  },
  object: {
    loading: false,
    error: null,
    data: [],
  },
  loading: false,
  error: null,
};

const {
  XLOG_READ_BEGIN,
  XLOG_READ_SUCCESS,
  XLOG_READ_ERR,
  TXN_READ_BEGIN,
  TXN_READ_SUCCESS,
  TXN_READ_ERR,
  TXN_DETAIL_READ_BEGIN,
  TXN_DETAIL_READ_SUCCESS,
  TXN_DETAIL_READ_ERR,
  OBJECT_READ_BEGIN,
  OBJECT_READ_SUCCESS,
  OBJECT_READ_ERR,
} = actions;

const XLogReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case XLOG_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case XLOG_READ_SUCCESS:
      return {
        ...state,
        data: {
          loop: data.xlogLoop,
          index: data.xlogIndex,
          xlogs: [...state.data.xlogs, ...data.xlogs],
        },
        loading: false,
      };
    case XLOG_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TXN_READ_BEGIN:
      return {
        ...state,
        txn: {
          loading: true,
        },
      };
    case TXN_READ_SUCCESS:
      return {
        ...state,
        txn: {
          data,
          loading: false,
        },
      };
    case TXN_READ_ERR:
      return {
        ...state,
        txn: {
          error: err,
          loading: false,
        },
      };
    case TXN_DETAIL_READ_BEGIN:
      return {
        ...state,
        txnDetail: {
          loading: true,
        },
      };
    case TXN_DETAIL_READ_SUCCESS:
      return {
        ...state,
        txnDetail: {
          data,
          loading: false,
        },
      };
    case TXN_DETAIL_READ_ERR:
      return {
        ...state,
        txnDetail: {
          error: err,
          loading: false,
        },
      };
    case OBJECT_READ_BEGIN:
      return {
        ...state,
        object: {
          loading: true,
        },
      };
    case OBJECT_READ_SUCCESS:
      return {
        ...state,
        object: {
          data,
          loading: false,
        },
      };
    case OBJECT_READ_ERR:
      return {
        ...state,
        object: {
          error: err,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default XLogReducer;
